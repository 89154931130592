.notfound-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 30px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.notfound-text-div > p {
  font-size: 25px;
}
.notfound-btn-div {
  display: flex;
  gap: 30px;
}
.notfound-btn-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  color: white;
  background-color: #fe7272;
  font-size: large;
  border: 2px solid #fe7272;
}
.notfound-btn-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  color: white;
  background-color: #13a62a;
  font-size: large;
  border: 2px solid #13a62a;
}
.notfound-btn-2:hover {
  background-color: white;
  border: 2px solid #13a62a;
  color: #000;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .notfound-text-div > p {
    font-size: 18px;
  }
  .notfound-img-div > img {
    width: 300px;
  }
  .notfound-btn-div {
    flex-direction: column;
    gap: 10px;
  }
  .notfound-text-div {
    padding: 0px 15px;
  }
  .notfound-btn-div > button {
    scale: 0.9;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .notfound-text-div > p {
    font-size: 18px;
  }
  .notfound-img-div > img {
    width: 450px;
  }
  .notfound-text-div {
    padding: 0px 15px;
  }
  .notfound-btn-div {
    gap: 10px;
  }
  .notfound-btn-div > button {
    scale: 0.9;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .notfound-text-div > p {
    font-size: 20px;
  }
  .notfound-text-div {
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .notfound-text-div > p {
    font-size: 25px;
  }
  .notfound-text-div {
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1250px) {
  .notfound-text-div > p {
    font-size: 21px !important;
  }
}
@media only screen and (min-width: 1251px) and (max-width: 1300px) {
  .notfound-text-div > p {
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .notfound-text-div > p {
    font-size: 23px !important;
  }
}
