.schedulebox {
  width: 70%;
  height: max-content;
  /* border: 1px solid black; */
  background-color: #eef9fe;
  padding: 10px;
}
.monrow {
  display: flex;
  justify-content: space-between;
}
.monfirstdiv {
  flex: 1;
}
.monrowinputsdiv {
  display: flex;
  flex: 2.5;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 0 !important;
}
.monarrbtn {
  flex: 1;
}
.monhr {
  margin-top: 20px;
  width: 85%;
}
.monhrfirst {
  margin-top: 10px;
  width: 85%;
}
.tue-cb {
  margin-left: 5px;
}
.wed-cb {
  margin-left: 30px;
}
.thur-cb {
  margin-left: 10px;
}
.fri-cb {
  margin-left: -15px;
}
.sat-cb {
  margin-left: 10px;
}
.morning-text {
  padding-left: 30px;
}
.noon-text {
  padding-left: 40px;
}
.WeekDaysHolder {
  height: 300px;
  width: 500px;
  padding-inline: 20px;
  overflow-y: scroll;
}
.WeekDaysHolder::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
.WeekDaysHolder::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
  height: 200px !important;
}
/* .react-datepicker-wrapper{
  width: 0 !important;
} */
.addTimeBoxFrequency {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.days-box-outer {
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  gap: 1rem;
}
.days-box-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media only screen and (min-width: 320px) and (max-width: 450px) {
  .sm-isMobile {
    display: none;
  }
  
  .rmdp-input {
    width: 110px !important;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }
  .selecttimeDiv {
    margin-left: 5%;
    margin-top: -13%;
  }
  .mobdose-dose {
    /* margin-left: 21%; */
  }
  .mobdose-dose_medicine {
    margin-left: -14%;
    margin-top: -45px;
  }
  .selecttimeDiv {
    width: 400px;
    overflow-x: scroll;
    margin-top: -28px;
  }
  .selecttimeDiv_mymedicine {
    width: 400px;
    /* overflow-x: scroll; */
    margin-top: 10px;
  }
  .frequencyInput {
    min-width: 100px !important;
  }
  .days-box-outer {
    flex-wrap: nowrap;
  }
  .days-box-inner {
    flex-wrap: nowrap;
  }
  .err-day-select {
    margin-left: 0 !important;
  }
  .dayDiv {
    margin-left: 3% !important;
  }
  .afternoonInput {
    /* margin-left: 20px; */
  }
  .check-day-sty {
    min-width: 85%;
  }
  .schedulebox {
    width: 100% !important;
  }
  .sm-mobile-startdate {
    /* margin-left: 9% !important; */
  }
  .mob-text-startdate {
    margin-left: 35px;
  }
  .mob-startdate {
    width: 70%;
  }
  .mob-enddate {
    width: 67%;
  }
  .sm-mobile-timezone {
    margin-left: 54px !important;
  }
  .sm-mobile-enddate {
    /* margin-left: 7% !important; */
    /* padding-right: 43px; */
    width: 70%;
  }
  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .checkbox-mob {
    scale: 1 !important;
    margin-top: 15px;
  }
  .checkbox-morning-mob {
    scale: 1 !important;
    margin-top: 54px;
  }
  .timing-mob {
    margin-top: 45px;
  }
  .select-doese {
    margin-left: 0px !important;
  }
  .afternoon-dose-mob {
    margin-top: 29px;
    margin-left: 15px !important;
  }
  .evening-dose-mob {
    margin-top: 29px;
    margin-left: 20px !important;
  }
  .mob-hr {
    margin-left: 20px;
  }

  .afternoon-time-mob {
    margin-top: 30px !important;
  }
  .morning-text-mob {
    margin-top: 7px !important;
  }
  .afternoon-text {
    margin-top: -11px !important;
  }
  .evening-dose-mob {
    margin-top: 30px;
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 451px) and (max-width: 600px) {
  .sm-isMobile {
    display: none;
  }
  .mob-enddate {
    width: 67%;
  }
  .rmdp-input {
    width: 110px !important;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }

  .mobdose-dose_medicine {
    margin-left: -14%;
    margin-top: -23px;
  }
  .dayDiv {
    margin-left: 3% !important;
  }
  .err-day-select {
    margin-left: 0 !important;
  }
  .afternoonInput {
    margin-left: 20px;
  }
  .check-day-sty {
    min-width: 85%;
  }
  .schedulebox {
    width: 98% !important;
  }
  .sm-mobile-startdate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-enddate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-timezone {
    margin-left: 0 !important;
    padding-left: 15px;
  }

  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .days-box-outer {
    flex-wrap: nowrap;
  }
  .days-box-inner {
    flex-wrap: nowrap;
  }
  .selecttimeDiv {
    width: 400px;
    overflow-x: scroll;
    margin-top: 10px;
  }
  .frequencyInput {
    min-width: 120px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .sm-isMobile {
    display: none;
  }
  .rmdp-input {
    width: 110px !important;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }
  .selecttimeDiv {
    margin-left: 5%;
    margin-top: -13%;
  }
  .mobdose-dose {
    /* margin-left: 21%; */
  }
  .dayDiv {
    margin-left: 3% !important;
  }
  .err-day-select {
    margin-left: 0 !important;
  }
  .afternoonInput {
    margin-left: 20px;
  }
  .check-day-sty {
    min-width: 85%;
  }
  .schedulebox {
    width: 82% !important;
  }
  .sm-mobile-startdate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-enddate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-timezone {
    margin-left: 0 !important;
    padding-left: 15px;
  }

  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .days-box-outer {
    flex-wrap: nowrap;
  }
  .days-box-inner {
    flex-wrap: nowrap;
  }
  .selecttimeDiv {
    width: 600px;
    overflow-x: scroll;
    margin-top: 10px;
  }
  .frequencyInput {
    min-width: 110px !important;
    width: 150px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .sm-isMobile {
    display: none;
  }
  .rmdp-input {
    width: 110px !important;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }
  .err-day-select {
    margin-left: 0 !important;
  }
  .mobdose-dose {
    /* margin-left: 21%; */
  }
  .dayDiv {
    margin-left: 3% !important;
  }
  .afternoonInput {
    margin-left: 20px;
  }
  .check-day-sty {
    min-width: 85%;
  }
  .schedulebox {
    width: 98% !important;
  }
  .sm-mobile-startdate {
    margin-left: 0 !important;
    padding-left: 15px;
  }

  .sm-mobile-timezone {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-enddate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  .smp-day {
    padding-left: 40px;
  }
  .smp-btn {
    padding-right: 40px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .smp-monarr-div {
    display: flex;
    width: 680px;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .sm-isMobile {
    display: none;
  }
  .evening-sty {
    margin-left: 30px ;
    width: 60px;
  }
  .rmdp-input {
    width: 110px !important;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }
  .schedulebox {
    width: 80% !important;
  }
  .sm-mobile-startdate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-enddate {
    padding-left: 15px;
    margin-left: 0 !important;
  }
  .sm-mobile-timezone {
    margin-left: 0 !important;
    padding-left: 15px;
  }

  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  .smp-day {
    padding-left: 40px;
  }
  .smp-btn {
    padding-right: 40px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .smp-monarr-div {
    display: flex;
    width: 680px;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .sm-isMobile {
    display: none;
  }
  .evening-sty {
    margin-left: 30px ;
    width: 60px;
  }
  .schedulebox {
    width: 80% !important;
  }
  .sm-mobile-startdate {
    margin-left: 0 !important;
    padding-left: 15px;
  }
  .sm-mobile-enddate {
    padding-left: 15px;
    margin-left: 0 !important;
  }
  .sm-mobile-timezone {
    margin-left: 0 !important;
    padding-left: 15px;
  }

  .smp-day-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  .smp-day {
    padding-left: 40px;
  }
  .smp-btn {
    padding-right: 40px;
  }
  .smp-hr1 {
    margin-bottom: -30px !important;
    margin-top: 50px;
  }
  .smp-hr {
    margin-top: 40px;
    margin-bottom: -30px;
  }
  .smp-hr2 {
    margin-top: 40px;
  }
  .smp-date-input {
    margin-left: -85px !important;
  }
  .smp-monarr-div {
    display: flex;
    width: 680px;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  .schedulebox {
    width: 94%;
  }
  .smp-main {
    display: none;
  }
  .evening-sty {
    margin-left: 30px ;
    width: 60px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .schedulebox {
    width: 88%;
  }
  .smp-main {
    display: none;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .schedulebox {
    width: 82%;
  }
  .smp-main {
    display: none;
  }
}
@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .schedulebox {
    width: 78%;
  }
  .smp-main {
    display: none;
  }
}
@media only screen and (min-width: 1801px) and (max-width: 2400px) {
  .schedulebox {
    width: 72%;
  }
  .smp-main {
    display: none;
  }
}

@media (max-width: 379px) {
  .frequencyPopup {
    width: 97%;
    top: 50%;
  }
  .mobdose-dose_medicine {
    margin-left: -14%;
    margin-top: -45px;
  }
  .dayDiv {
    margin-left: 3% !important;
  }
  .addTimeBoxFrequency {
    display: block;
  }
  .WeekDaysHolder {
    width: 110%;
  }
  .frequencyInput {
    min-width: none !important;
    width: 90%;
  }
}
@media (min-width: 380px) and (max-width: 600px) {
  .frequencyPopup {
    width: 90%;
    top: 50%;
    /* scale: 0.7; */
  }
  .mobdose-dose_medicine {
    margin-left: -14%;
    margin-top: -45px;
  }
  .rmdp-input {
    width: 110px !important;
  }
  .evening-sty {
    margin-left: 20px !important;
    width: 60px;
  }
  .mob-timing {
    display: flex;
    gap: 14px;
    justify-content: flex-start;
  }
  .WeekDaysHolder {
    width: 110%;
  }
  .frequencyInput {
    min-width: none !important;
    width: 90%;
  }
}
.crossBtn {
  background-color: #fe7272;
  border: none;
  color: white;
  font-size: larger;
  border-radius: 5px;
}
.tableHeadSty {
  background-color: #6d8598;
  border: 1px solid #000;
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  padding: 10px;
}

.addbtnfrequency {
  height: 35px;
  padding-inline: 15px;
  font-size: medium;
}
.addbtnfrequency:hover {
  height: 35px;
  padding-inline: 15px;
  font-size: medium;
}
.morningtext {
  padding-inline: 0px;
  margin-right: 10px;
}
.scheduleidinput {
  min-width: 20px !important;
  max-width: 150px;
}
.inputarrstyle {
  width: 200%;
  gap: 4;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 3;
}
.addstyle {
  margin-left: 5%;
}
.moncheksty {
  width: 20%;
}
.VerticalLine {
  width: 129px;
  /* border-left: 9px solid green; */
  /* height: 19px; */
  border-left: 6px solid green;
  height: 119px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 475px;
}

.check-day-sty {
  width: 10%;
}
.dayDiv {
  margin-left: 11%;
}
.selecttimeDiv {
  margin-left: 5%;
}
.select-doese {
  width: 60px;
  margin-left: -89px;
}
.select-doese_medicine {
  width: 60px;
  /* margin-left: -89px; */
}
.err-day-select {
  margin-left: -280px;
}
.heding_timing {
  display: flex;
  justify-content: center;
}
.morning-top {
  margin-top: 2.5rem;
}
.dose-text {
  font-size: 1.2rem !important;
}

.dose-input {
  margin-left: -15.5%;
}
.rmdp-input {
  width: 220px;
}
.rmdp-time-picker div input {
  width: 30px !important;
}
.evening-dose {
  margin-left: 27px !important;
}
.evening-sty {
  margin-left: 30px ;
  width: 60px;
}
.text-morning{
  width: 90px;
}
.dose-select-sty{
  margin-left: 121px;
}
.text-afternoon-sty{
  width: 107px;
}
.dose-select-mon{
  margin-left: 20px;
}

.width-mon-morning{
  width: 130px;
}