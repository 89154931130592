.contactbox {
  margin-top: 7%;
}

.awesome-form {
  background: #ffffff;
  width: 100%;
  max-width: 600px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.awesome-form:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.awesome-form h2 {
  margin-bottom: 30px;
  color: #333;
}

.awesome-form .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

.awesome-form .form-control:focus {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.awesome-form .btn {
  border-radius: 5px;
  background: #007bff;
  color: #fff;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}

.awesome-form .btn:hover {
  background: #0056b3;
}
