@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --ContainerColor: #daeaf1;
  --ThemeTextColor: #303847;
  --ThemeBtnBgColor: #4b89ac;
  --ThemeBtnTextColor: #ffffff;
  --OnHoverBtnBgColor: #ffffff;
  --OnHoverBtnBorderColor: #303847;
  --PopupBgColor: #ffffff;
  --PopupBorderColor: #303847;
}
body {
  /* font-family: "Wix Madefor Display", sans-serif; */
  font-family: "Poppins", sans-serif;
}
.ErrMessage {
  color: red;
  font-size: 15px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="checkbox"] {
  min-width: 0px;
  box-shadow: none;
  scale: 1.2;
}
input,
textarea {
  background-color: transparent;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  /* min-width: 220px; */
  padding: 6px;
  text-indent: 6px;
  /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-bottom: 3px solid #303847;
}
input::placeholder,
textarea::placeholder {
  color: #89919e;
}
select {
  background-color: transparent;
  border: 1px solid #787878;
  border-radius: 4px;
  padding: 6px;
  width: 234px;
}
select>option{
  width: 234px;
}
select::selection{
  width: 30px !important;
}
.cancelbtn {
  background-color: #fe7272;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: 100px;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
}
.savebtn {
  background-color: #13a62a;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: 102px;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
}
.savebtn:hover {
  background-color: white;
  border: 2px solid #13a62a;
  border-radius: 4px;
  color: #000;
  width: 100px;
  font-size: large;
  height: 40px;
}
.savebtn:disabled:hover{
  border: 2px solid rgb(44, 44, 44);
  cursor: not-allowed;
}
.savebtn:disabled{
  background-color: transparent;
  color: black;
  border: 2px solid rgb(44, 44, 44);
    opacity: 0.5;

}
.updatebtn{
  background-color: #13a62a;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  width: 100px;
  height: 37px;
  font-size: medium;
}
.pairbtn{
  background-color: #406cc4;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: 100px;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
}
.pairbtn:hover {
  background-color: white;
  border: 2px solid #406cc4;
  border-radius: 4px;
  color: #000;
  width: 100px;
  font-size: large;
  height: 40px;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #406cc4;
    color: #ffffff;
}
}
.DisableBtn {
  background-color: transparent;
  border: 1px solid #ea8a7d;
  border-radius: 4px;
  color: #000000;
  width: 100px;
  height: 37px;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
}
.roundedBtn {
  background-color: var(--ThemeBtnBgColor);
  border: none;
  border:2px solid var(--ThemeBtnBgColor);
  border-radius: 99px;
  color: var(--ThemeBtnTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 20px;
}
.roundedBtn:hover {
  background-color: transparent;
  border: 2px solid var(--OnHoverBtnBorderColor);
  border-radius: 99px;
  color: var(--ThemeTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 20px;
}
.icons {
  width: 28px;
  height: 28px;
}

.Popup {
  z-index: 100;
  background-color: var(--PopupBgColor);
  border: 1px solid var(--PopupBorderColor);
  border-radius: 20px;
  padding: 20px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blurBg {
  filter: blur(3px);
}
@media (min-width: 376px) and (max-width: 600px) {
/* .roundedBtn-new-medicine{
  height: 80px !important;
} */
}
@media (max-width: 641px) {
  .roundedBtn-new-medicine{
    height: 45px !important;
  padding-inline: 10px;

  }
}