.addmedicineBox {
  background-color: var(--ContainerColor);
  margin-top: 40px;
  border-radius: 10px;
}
.box-size {
  margin-left: 9%;
}
.medicine-select {
  width: 50%;
}
.incres_decres {
  background: transparent;
  border: none;
  margin-top: -4px;
}
.dosecount {
  margin: 0px 15px 0px 15px;
  border-bottom: 3px solid #6d9886;
  width: 40px;
  text-align: center;
}
.reminder {
  margin-top: -66px;
  margin-left: 322px;
}
.week_btn {
  width: 10%;
  height: 44px;
  color: #fff;
  border: none;
  border-radius: 7px;
  background-color: #fe7272;
}
.doseadd {
  border-bottom: 2px solid black;
}
.AddDose {
  border: none;
  background-color: transparent;
  color: #fe7272;
  font-weight: 600;
  font-size: larger;
  margin-left: 80px;
}
@media (max-width: 600px) {
  .reminder {
    margin-top: 5px;
    margin-left: 0px;
  }
  .week_btn {
    width: 18%;
  }
}
/* Toggle css */
.toggle {
  position: relative;
  box-sizing: border-box;
}
.toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
}
.toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.toggle label:before {
  content: "";
  width: 55px;
  height: 25px;
  background: #fff;
  border: 2px solid #fe7272;
  box-shadow: inset -30px 0 0 #fe7272;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 0.25s ease-in;
}

.toggle label:after {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  left: 12px;
  top: 15px;
  border-radius: 7px;
  border: 0px solid #fe7272;
  transition: 0.2s ease-in-out;
}

.toggle input[type="checkbox"]:checked + label:before {
  box-shadow: inset 30px 0 0 #66d878;
  border-color: #66d878;
}
.toggle input[type="checkbox"]:checked + label:after {
  left: 45px;
  border-color: #66d878;
  box-shadow: 0px -8px 0 -4px #fff, 0px -8px 0 -2px #66d878;
}
