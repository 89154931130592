.viewschedulebtn {
  background-color: var(--ThemeBtnBgColor);
  border: none;
  border: 2px solid var(--ThemeBtnBgColor);
  border-radius: 99px;
  color: var(--ThemeBtnTextColor);
  width: max-content;
  height: 35px;
  font-size: medium;
  padding-inline: 10px;
}

.viewschedulebtn:hover {
  background-color: transparent;
  border: 2px solid var(--OnHoverBtnBorderColor);
  border-radius: 99px;
  color: var(--ThemeTextColor);
  width: max-content;
  height: 38px;
  font-size: medium;
  padding-inline: 10px;
}

.schedulepopup {
  z-index: 1000;
  height: 80%;
  min-height: max-content;
  width: 50%;
  top: 13%;
  background-color: white;
  position: fixed;
  right: 25%;
  border-radius: 20px;
  align-items: center;
  padding: 1%;
  color: #000000;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 0px 2px 2px 2px;
}
.schedulepopup_asneeded {
  z-index: 1000;
  height: 331px;
  width: 30%;
  bottom: 33%;
  background-color: white;
  position: fixed;
  right: 37%;
  border-radius: 20px;
  align-items: center;
  padding: 1%;
  color: #000000;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 0px 2px 2px 2px;
}

.scheduleboxpopup {
  width: 100%;
  height: 492px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #eef9fe;
  padding: 10px;
}
.scheduleboxpopup-asneeded {
  width: 100%;
  height: 147px;
  background-color: #eef9fe;
  padding: 10px;
}

.monchekstypopup {
  width: 18%;
}
.asneedchekstypopup {
  width: 50%;
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .scheduleboxpopup {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    /* width: 100%; */
    height: 550px;
    background-color: #eef9fe;
    padding: 10px;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .schedulepopup {
    width: 95%;
    right: 3%;
  }
  .scheduleboxpopup {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    /* width: 100%; */
    height: 550px;
    background-color: #eef9fe;
    padding: 10px;
  }
  .schedulepopup_asneeded {
    width: 60%;
    right: 17%;
  }

  #medicineviewtime {
    width: 100px;
  }
}

@media (max-width: 379px) {
  .schedulepopup {
    width: 95%;
    right: 2%;
    bottom: 4% !important;
    /* height: 550px; */
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .mobdose-dose_medicine-mymedicine {
    margin-left: -14%;
    margin-top: -46px;
  }
  .dose-evening {
    width: 102% !important;
  }
  .Mob-Morning-timing {
    width: 70% !important;
  }
  .mob-afternoon-timing-new {
    min-width: 40% !important;
  }
  .schedulepopup_asneeded {
    width: 82%;
    right: 10%;
    height: 490px;
    top: 10%;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  #medicineviewtime {
    width: 20px;
  }
}

.mob-sty-dose-puff {
  width: 80% !important;
}

.text_asneeded {
  font-size: larger;
}
@media (max-width: 512px) {
  .schedulepopup {
    width: 89%;
    right: 6%;
    bottom: 18%;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .mob-hr-mymedicine {
    margin-left: 33px;
  }
  .evening-checkbox-mob {
    margin-bottom: -9px !important;
  }
  .mob-evening-checkbox {
    gap: 0 !important;
    margin-top: 18px;
  }
  .mob-days-box {
    margin-bottom: 27px !important;
    margin-top: -48px;
  }
  .mob-div {
    margin-top: -73px;
    margin-left: 119px;
  }
  .mob-evening-sty {
    margin-top: -28px;
  }
  .input-timing {
    width: 106% !important;
    margin-left: -1px !important;
  }
  .input-timing-evening {
    margin-left: 0 !important;
    width: 106% !important;
  }
  .input-timing-night {
    margin-left: 0 !important;
    width: 106% !important;
  }
  .text-afternoon {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .dose-input {
    width: 61% !important;
    margin-left: 0px !important;
  }
  #timing-mob {
    width: 106% !important;
  }
  .morning-time {
    width: 111% !important;
  }
  .afternoon-time {
    width: 90% !important;
  }
  .dose-evening {
    width: 71% !important;
  }
  .mob-afternoon-timing-new {
    min-width: 40% !important;
  }
  .mobdose-dose_medicine-mymedicine {
    margin-left: -14%;
    margin-top: -46px;
  }
  .mob-sty-dose-puff {
    width: 80% !important;
  }
  .mob-afternoon-timing {
    width: 90% !important;
  }
  .Mob-Morning-timing {
    width: 70% !important;
  }
  .mob-dose-sty {
    margin-left: 9px !important;
  }
  .mob-dose-sty-afternoon {
    margin-top: 45px;
    margin-left: 9px !important;
  }
  .mob-sty-dose-puff {
    width: 80% !important;
  }

  .afternoon-checkbox-mob {
    margin-bottom: 0 !important;
  }
  .mob-mon-text {
    margin-top: 4px !important;
  }
  .schedulepopup_asneeded {
    width: 82%;
    right: 10%;
    height: 490px;
    top: 12%;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  #medicineschdulemobile {
    flex-direction: row;
    gap: 100px !important;
  }

  .scheduleboxpopup {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 400px;
    background-color: #eef9fe;
    padding: 10px;
  }
  #eveningtext {
    margin-left: 10%;
  }
  #afternoontext {
    margin-left: 10%;
  }
  #morningtext {
    margin-left: 10%;
  }
  #fridaygapsty {
    gap: 7% !important;
  }
}
#fridaygapsty {
  gap: 1%;
}
.dose-time {
  width: 98px;
}
.time-dose-div {
  margin-left: 33px;
}
.w-medicine {
  width: 60px;
}
.left-dose {
  margin-left: 4px;
}
.check-day-sty-medicine {
  width: 13.7%;
}
@media screen and (max-width: 599px) {
  .bcYJHc {
    justify-content: space-around;
  }
  .mobdose-dose_medicine-mymedicine {
    margin-left: -14%;
    margin-top: -46px;
  }
}

.medicine-width {
  width: 94%;
  padding-left: 0px;
}
.text-as-needed {
  font-size: larger;
  font-weight: 500;
}
@media (min-width: 380px) and (max-width: 600px) {
  .mobdose-dose_medicine-mymedicine {
    margin-left: -14%;
    margin-top: -46px;
  }
  .morning-time {
    width: 111%;
  }
  .afternoon-time {
    width: 90% !important;
  }
}
.morning-time {
  width: 71%;
}
.afternoon-time {
  width: 71%;
}
.input-timing {
  width: 75%;
  margin-left: -1px;
}
.text-afternoon {
  margin-left: 20px;
  margin-top: -24px;
}
.dose-input {
  width: 25%;
  margin-left: 19px;
}
.input-timing-evening {
  margin-left: 18px;
  width: 70%;
}
.input-timing-night {
  margin-left: 44px;
  width: 63%;
}
.evening-timing-title {
  margin-left: 5px;
}
.evening-checkbox-mob {
  margin-bottom: -6px;
}
.night-days-sty {
  margin-top: -60px;
}
.scheduleinputmymedicine {
  width: 140px !important;
}