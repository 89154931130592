.report-main {
  /* background-color: #f0f0f0; */
  /* height: 100vh; */
}

.card-main {
  display: flex;
  justify-content: center;
  gap: 25px;
}
.report-card-box {
  background-color: #daeaf1;
  height: 250px;
  width: 270px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 0.2px solid rgba(99, 99, 99, 0.096);
}
.download-report-btn {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff6969;
  color: white;
}
.download-report-btn:hover {
  background-color: #2d2727;
  cursor: pointer;
}
.report-chart {
  height: 400px;
  background-color: white;
  border-radius: 10px;
}
@media only screen and (max-device-width: 480px) {
  .card-main {
    overflow-x: scroll;
    justify-content: start;
  }
}
