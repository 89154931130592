.Cancelbtnforgot {
  background-color: #fe7272;
  width: 250px;
  height: 40px;
  color: white;
  border-radius: 8px;
  border: 1px solid #fe7272;
}
.forgotBox {
  top: 32%;
}
.help_text{
  color: #1a2e35;
}
.forgotsuccess{
  color:green;
  text-align: center;
  margin-bottom: -10px;
  /* margin-top: 8px; */
  font-weight: 500;
  font-size: 30px;
}