.LoginHalfBG {
  background-color: #e7f5f5;
  height: 106vh;
  width: 102.5%;
  position: relative;
}
.signup_link {
  color: #6600ff;
  text-decoration: none;
}

.imgstyle {
  height: 500px;
  margin-left: 20%;
  margin-top: 20%;
}
.loginMain {
  max-width: 100%;
}
.LoginBox {
  background-color: white;
  position: absolute;
  border-radius: 25px;
  border: none;
  top: 20%;
  width: 60%;
  left: 16%;
  margin-inline: 40px;
  padding: 20px;
}
.forgotpasstext {
  color: #6600ff;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
}
.forgotpasstext:hover {
  text-decoration: none;
  color: #6600ff;
}
.css-i44wyl {
  width: 90%;
}
.dummy {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 375px) {
  .LoginBox {
    margin-left: -46px !important;
    width: 100%;
    top: 36px;
  }
  .form-input-login {
    width: 103% !important;
    align-items: center !important;
    margin-left: -15px !important;
  }
  .dummy {
    display: block;
  }
  .forgotpasstext {
    margin-left: 63px;
  }
}
@media (min-width: 380px) and (max-width: 600px) {
  #mobilebg {
    display: none;
  }
  .dummy {
    display: block;
  }
  .forgotpasstext {
    margin-left: 63px;
  }
  .LoginHalfBG {
    background-color: #e7f5f5;
  }

  .LoginBox {
    background-color: #d5f7e6;
    position: absolute;
    border-radius: 25px;
    border: none;
    top: 6%;
    width: 100%;
    left: 0%;
    /* margin-inline: 40px; */
    /* padding: 20px; */
  }
  .LoginHalfBG {
    width: 45vh;
    background-color: #e7f5f5;
  }
  .form-input-login {
    width: 103% !important;
    align-items: center !important;
    margin-left: -20px !important;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .LoginBox {
    left: 0% !important;
    margin-left: 50%;
  }
  .form-input-login {
    margin-left: 0px !important;
    width: 100% !important;
  }
}
@media (max-width: 1024px) {
  #mobilebg {
    display: none;
  }
}
@media (min-width:1225px) and (max-width:1605px){
  .LoginBox {
    left: 0% !important;
    margin-left: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .LoginHalfBG {
    background: #e7f5f5;
    height: 100vh;
  }
  .LoginBox {
    top: 23%;
    background-color: #ffff;
    width: 70%;
  }
}
@media (max-width: 1606px) {
  .LoginBox {
    background-color: #e7f5f5;
    /* margin-left: 330px; */
    width: 98%;
  }
  .LoginHalfBG {
    background-color: white;
  }
  .LoginHalfBG {
    background: #fff;
  }
}
@media (min-width: 1024px) and (max-width: 1210px) {
  .LoginHalfBG {
    background: #fff;
    height: 100vh;
  }
  .LoginBox {
    top: 18%;
    margin-left: 250px !important;
  }
}

.ImgContainer {
  position: absolute;
  top: 18%;
  width: 60%;
  left: 16%;
}
.loginImgBox {
  position: relative;
}

.HeadlineTitle {
  color: #344054;
}

.HeadlineText {
  color: #667085;
  font-size: large;
  font-weight: 500;
}
.form-input-login {
  width: 70%;
  align-items: center !important;
  margin-left: 85px;
}
.err_login {
  margin-left: 34px;
}
.LoginForm {
  margin-top: 10px;
}
.login-input {
  margin-left: 33px !important;
}

.LoginInputSty {
  border: none;
  border-radius: 7px;
  min-width: 240px;
  height: 50px;
  text-indent: 12px;
  width: 100%;
}
.eyebtn {
  background: transparent;
  border: none;
  margin-left: -40px;
}

.SignInBtn {
  background-color: #1a2e35;
  width: 250px;
  height: 40px;
  color: white;
  border-radius: 8px;
}
.MobileSignInBtn {
  background-color: #fff;
  width: 250px;
  height: 40px;
  color: #344054;
  border-radius: 8px;
  margin-top: 20px;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 88%;
}
.LoginLogoSty {
  width: 230px;
}
.tooltipSty {
  height: 13px;
}
.tooltipinfo {
  font-size: 11px;
}
.troubletext {
  color: black;
}
.verify-input {
  width: 50%;
}
.text_account {
  color: #0085e4;
}
.Email-icon{
  height: 55px;
  width: 55px;
}
.regErr-p{
  color:red;
  text-align: center;
  margin-bottom: -10px;
  /* margin-top: 8px; */
  font-weight: 500;
  font-size: 20px;
}