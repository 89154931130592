.inputaddDevice {
  width: 250px;
}
.searchMedicineInput {
  width: 300px;
}

.searchBtn {
  background-color: var(--ThemeTextColor);
  border: 2px solid var(--ThemeTextColor);
  border-radius: 10px;
  color: var(--ThemeBtnTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 20px;
}
.searchBtn:hover {
  background-color: transparent;
  border: 2px solid var(--OnHoverBtnBorderColor);
  border-radius: 10px;
  color: var(--ThemeTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 20px;
}
.SelectedBtn {
  background-color: var(--ThemeBtnTextColor);
  border: none;
  border-radius: 10px;
  color: var(--ThemeTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 20px;
  border: 1px solid var(--OnHoverBtnBorderColor);
}
.medicineBox {
  background-color: #ecd5e3;
  padding: 15px;
  /* padding-inline-start: 30px; */
 
  /* height: auto; */
  border-radius: 10px;
  margin: 4px;
  min-width: 347px;
}
.medicinetext {
  font-weight: 600;
  letter-spacing: 1px;
}
.manuallybox {
  margin-left: 10%;
}
.searchbox {
  display: flex;
  justify-content: center;
}
.addDeviceFormBox {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.manuallymedicineBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
@media (min-width: 601px) and (max-width: 1070px) {
  .addDeviceInput {
    margin-left: 10px;
    margin-top: 1rem;
  }
  .addMedicineInput {
    margin-left: 10px;
    margin-top: 1rem;
  }
  .searchbox {
    /* display: block; */
    margin-left: 0%;
  }
  /* .addMedicineInput > .inputaddDevice {
    width: 97%;
  } */
  
  .addDeviceInput > .savebtn {
    width: max-content;
  }
  .manuallymedicineBox {
    justify-content: flex-start;
  }
  .manuallymedicineBox > .addMedicineInput > input {
    width: 100px;
  }
  .btn-width-med {
    width: max-content !important;
  }
}
/* @media (min-width: 960px) and (max-width: 1224) {
  
} */
@media (max-width: 375px) {
  .addDeviceInput > h5 {
    width: max-content;
  }
  .addDeviceInput {
    margin-top: 1rem;
  }
  .scroll_medicine{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .alert-time-zone-sty{
    display: fl
  }
  .addMedicineInput {
    margin-top: 1rem;
  }
  .alert-time{
    margin-top: 27px;
  }
  .input-number-in-days{
    width: 27% !important;
  }
  .input-number-alert{
    width: 24% !important;
  }
  .searchbox {
    display: block;
    margin-left: 0%;
  }
  .searchbox > .searchboxbtn {
    margin-top: 1rem !important;
    display: flex;
    justify-content: center;
    margin-left: 0%;
  }
  .addDeviceFormBox {
    display: block;
  }
  .addDeviceInput > .savebtn {
    width: max-content;
  }
  .manuallymedicineBox{
    flex-direction: column;
  }
  .btn-width-med {
    width: max-content !important;
  }
  .medicineBox{
    width: 280px;
  }
  .DeviceBox{
    min-width: 200px !important;
  }
  .DeviceLogo{
    
  }
}
@media (min-width: 380px) and (max-width: 600px) {
  .addDeviceInput > h5 {
    width: max-content;
  }
  .addDeviceInput {
    margin-top: 1rem;
  }
  .addMedicineInput {
    margin-top: 1rem;
  }
  .searchbox {
    display: block;
    margin-left: 0%;
  }
  .searchbox > .searchboxbtn {
    margin-top: 1rem !important;
    display: flex;
    justify-content: center;
  }
  .addDeviceFormBox {
    display: block;
  }
  .addDeviceInput > .savebtn {
    width: max-content;
  }
  .manuallymedicineBox{
    flex-direction: column;
  }
  .btn-width-med {
    width: max-content !important;
  }
}
.btn-width {
  width: 134px;
}
.btn-width-med {
  width: 140px;
}
.btn-width:hover {
  width: 134px;
}
.btn-width-med:hover {
  width: 140px;
}
.skipbtn {
  width: 85px;
}
.step3Sty {
  margin-left: -30px;
}

.DeviceBox {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #eef9fe;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  min-width: 450px;
  min-height: 100px;
  margin: 3px;
}
.DeviceLogo {
  width: 90px;
  height: 90px;
  border-radius: 9999px !important;
  /* outline-color: #207a48; */
  /* outline-style: solid !important; */
}
.deviceNameText{
  max-width:200px;
  word-wrap: break-word;
}
.imgSty {
  width: 70px;
  height: 70px;
}
.stepnumber {
  scale: 1.2;
}
.verticalLine {
  border-left: 1px solid black;
  height: 30px;
  position: absolute;
  left: 17.2%;
  top: 33.9%;
}
.verticalLine2 {
  border-left: 1px solid black;
  height: 30px;
  position: absolute;
  left: 127.2%;
  top: -25%;
}
.stepPosition {
  margin-left: 20px;
}
.step2position {
  display: grid;
  margin-left: 42.5%;
  margin-top: 5px;
}
.manual_sty {
  margin-left: -50%;
}
.medSaveBtn {
  width: 120px;
}
.medSaveBtn:hover {
  width: 120px;
}
.stepImage {
  height: 35px;
  width: 35px;
}
.step3_sty {
  margin-left: 42%;
}
.button_position {
  margin-left: -51%;
}
.searchText {
  color: red;
  animation: animate 3.5s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.1;
  }
}
.scroll_medicine {
  border-radius: 0.7rem;
  padding-bottom: 0.5rem;
  display: flex;
  overflow: auto;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.scroll_medicine::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.scroll_medicine::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
  height: 200px !important;
}
.mainBox{
      display: flex;
    justify-content: center;
}
.size-reg-page-date{
min-width: 250px;
}
.alert-title{
  margin-top: 1.5rem;
}
.input-number-alert {
  position: relative;
  margin: 0 0 1rem;
  border: 1px solid #BBB;
  border-color: #BBB #c7b9b9 #c7b9b9 #BBB;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-left: none;
  padding: .2rem;
  width: 17%;
  height: 38px;
}
.input-number-alert:focus{
  border: 1px solid #BBB;
  border-left: none;
}
.input-number-alert:disabled{
  background-color: #eee;
	cursor: not-allowed;
}
/* Spin Buttons modified */
.input-number-alert::-webkit-outer-spin-button, 
.input-number-alert::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
  width: 1em;
  border-left: 1px solid #BBB;
  opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.input-number-alert::-webkit-inner-spin-button:hover,
.input-number-alert::-webkit-inner-spin-button:active{
  box-shadow: 0 0 2px #0CF;
  opacity: .8;
}
.registermedicine-date{
  width: 174px !important;
  background-color: transparent;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  /* min-width: 283px; */
  padding: 6px;
  text-indent: 6px;
    /* margin-left: 20px; */
}
.registermedicine-buy{
  margin-left: 22px;
}
.timezone-addmedicine{
  width: 107%;
  /* height: 5%; */
}
.input-number-in-days{
  width: 15%;
  position: relative;
  /* margin: 0 0 1rem; */
  border: 1px solid #BBB;
  border-color: #BBB #c7b9b9 #c7b9b9 #BBB;
  /* padding: .2rem; */
  border-right: none;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
padding-left: 3px;
  /* width: 35%; */
  color: #a1a1a1;
  height: 38px;
}

.input-number-in-days:focus{
  border-bottom: 1px solid  #BBB;
  outline: none;
}
.timezone-sty{
  border: 1px solid #c0c4d6 !important;
}