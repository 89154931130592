.registerBG {
  background-color: #e7f5f5;
  height: 100vh;
}
.registerBGIMG {
  height: 90vh;
}
.main_bg {
  height: 100%;
  width: 99%;
}
.form-sty {
  width: 90%;
}
.row_heigth {
  background-size: auto;
}
.input-sty-all {
  margin-left: 40px;
}
.RegisterBox {
  background-color: white;
  position: sticky;
  border-radius: 25px;
  border: none;
  top: 15%;
  margin-inline: 131px;
  padding: 23px 20px 4px 84px;
}
.inputDivreg {
  width: 310px;
}

.register-counrty {
  width: 240px;
}

.registertext {
  color: #039be5;
  text-decoration: underline;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  box-shadow: none !important;
}

.css-1crxqij-MuiAutocomplete-root .MuiAutocomplete-input {
  box-shadow: none !important;
}
.confirmpass {
  width: 86% !important;
}
.css-1crxqij-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  border-bottom: none !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .registerBG {
    background-color: transparent;
  }
  .input-field:focus + .input-label {
    background-color: #e7f5f5 !important;
  }
  .RegisterBox {
    margin-inline: 3px !important;
    width: 100% !important;
  }
  .input-sty-all {
    margin-left: 90px;
  }
  .input-group {
    margin-left: -30px !important;
  }
  #registerBGmob {
    width: 100%;
  }
  .registersmallbox{
    margin-right: 12% !important;
  }
  .sign-in-link{
    margin-left: -47px;
  }
}
@media (max-width: 1605px) {
  .RegisterBox {
    width: 100%;
    top: 40px;
    margin-inline: 356px;
    background-color: #e7f5f5;
  }
  .input-sty-all {
    margin-left: 70px;
  }
  .input-group {
    margin-left: 70px;
  }
  #registerBGmob {
    background-color: #fff;
  }
  #mobilebg {
    display: none;
  }
}
@media (max-width: 1606px) {
  .RegisterBox {
    width: 115%;
    margin-inline: 240px;
  }
}
@media (min-width: 768px) and (max-width: 1150px) {
  .RegisterBox {
    background-color: #e7f5f5;
    position: absolute;
    border-radius: 25px;
    border: none;
    top: 13%;
    width: 79%;
    left: 4%;
    margin-inline: 40px;
    padding: 20px;
  }
  #registerBGmob {
    background-color: #fff;
  }

  #mobilebg {
    display: none;
  }
}

/* region inputbox style */
.input-container {
  position: relative;
}

/* Input field */
.input-field {
  display: block;
  width: 266px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  outline: none;
  height: 56px;
  background-color: transparent;
}
/* Input label */
.input-label {
  position: absolute;
  top: -7px;
  left: 0;
  font-size: 16px;
  color: rgba(204, 204, 204, 0);
  pointer-events: none;
  transition: all 0.2s ease;
}

Input highlight .input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #000;
  transition: all 0.3s ease;
  z-index: 1000;
  color: #fff;
}

/* Input field:focus styles */
.input-field:focus + .input-label {
  font-size: 12px;
  margin-left: 2px;
  color: #000;
  background: #fff;
}

.input-field:focus + .input-label + .input-highlight {
  width: 100%;
}
.Register-input {
  width: 50px !important;
  height: 50px;
}
