.sa-ca-btn-div {
  display: flex;
  justify-content: center;
  gap: 15px;
  /* background-color: pink; */
  margin-top: 15px;
}
.AppBarText {
  font-size: medium !important;
  font-weight: 600 !important;
  color: var(--ThemeTextColor) !important;
}

.MainAppBar {
  background: transparent !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(148, 138, 138);
}

.DeviceDataCardBox {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  width: 400px;
  height: 270px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
}

.DeviceDataBox {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  height: 270px;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  gap: auto;
  justify-content: space-between;
}

.DeviceDataCardBox:hover,
.DeviceDataBox:hover {
  cursor: pointer;
  scale: 1.01;
}

.addmedicinePopup {
  width: 90%;
  margin-top: 5%;
}

.addmedicinePopup2 {
  width: 50%;
}

.DashboardBox {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.ShareWithBox {
  background-color: #8dced261;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  width: 350px;
  height: 200px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-block: 7px;
  gap: 5px;
}
.TagnameBox {
  width: 100px;
  height: 100px;
  color: var(--ThemeTextColor);
  border-radius: 100px;
  text-align: center;
  padding-block: 30px;
}
.viewOtherBtn {
  background-color: var(--ThemeTextColor);
  border: none;
  border-radius: 99px;
  color: var(--ThemeBtnTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 50px;
}
.viewOtherBtn:hover {
  background-color: transparent;
  border: 2px solid var(--OnHoverBtnBorderColor);
  border-radius: 99px;
  color: var(--ThemeTextColor);
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 50px;
}
.declineBtn {
  background-color: #fe7272;
  border: none;
  border-radius: 99px;
  color: var(--ThemeBtnTextColor);
  height: 40px;
  padding-inline: 25px;
}
.declineBtn:hover {
  background-color: transparent;
  border: 2px solid #fe7272;
  border-radius: 99px;
  color: var(--ThemeTextColor);
  height: 40px;
  padding-inline: 25px;
}
.acceptBtn {
  background-color: #13a62a;
  border: none;
  border-radius: 99px;
  color: var(--ThemeBtnTextColor);
  height: 40px;
  padding-inline: 25px;
}
.acceptBtn:hover {
  background-color: transparent;
  border: 2px solid #13a62a;
  border-radius: 99px;
  color: var(--ThemeTextColor);
  height: 40px;
  padding-inline: 25px;
}
@media (min-width: 961px) {
  .DashboardBox {
    flex-wrap: nowrap;
  }
  .addmedicinePopup2 {
    width: 60%;
    flex-wrap: nowrap;
  }
}

@media (max-width: 641px) {
  .DashboardBox {
    justify-content: center;
  }

  .addmedicinePopup,
  .addmedicinePopup2 {
    width: 90%;
    margin-top: 10%;
  }
  .addmedicinePopup {
    margin-top: -1%;
  }
  .confirmPopup {
    width: 92%;
  }
  .sharedtopopup {
    width: 90% !important;
    margin-top: 4%;
  }
  .popupPairBtn:hover {
    border-radius: 4px;
    width: 30%;
    border: 2px solid #13a62a;
    padding: 2px;
  }
}

@media (max-width: 512px) {
  .access-data {
    margin-right: 0px !important;
  }
  .from-tilldate-sty {
    margin-left: 0 !important;
  }
  .main-div-sty {
    gap: 0 !important;
    display: block !important;
  }
  .email-div {
    gap: 0 !important;
  }
  .email-div-sty {
    margin-left: 0 !important;
  }
  .access-data-error {
    margin-left: 0 !important;
  }
  .select-device-sty {
    margin-left: 0 !important;
  }
  .nickname-div {
    margin-left: 0 !important;
  }
  .email-phone-div {
    margin-left: 0 !important;
  }

  .fromdate-sty {
    margin-left: 0 !important;
  }
  .dataperiod-fromdate {
    margin-left: 0 !important;
  }
  .select-device {
    width: 94% !important;
  }
  /* .nickname-input{
    width: 121% !important ;
  } */
  #shareto-input {
    width: 121%;
  }
  .nickname-input-sty {
    width: 94% !important ;
  }
  .email-input-sty {
    width: 115% !important;
  }
  .pairRefillPopup {
    width: 104% !important;
  }
  .fPIktX {
    width: 84% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .enddate-shareview {
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .mob-select-start-date {
    width: 70% !important;
  }
  .addmedicinePopup,
  .addmedicinePopup2 {
    /* width: 90%; */
    scale: 0.8;
    left: 42%;
  }
  .mob-shareto-date {
    width: 91%;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
  .confirmPopup {
    width: 92%;
  }
  .sharedtopopup {
    width: 93%;
    /* margin-left: 10px; */
  }
  .popupPairBtn {
    width: 54% !important;
  }
  .sharedtopopup {
    width: 90%;
  }

  .sharedtoinput {
    width: 100px;
  }
  .sharedtoinputdatepicker {
    min-width: 200px;
    z-index: 1000 !important;
  }
}

.icon_uparrow {
  margin-left: 5px;
  height: 18px;
  margin-bottom: 2px;
  width: 18px;
}
.sharedtoinput {
  width: 300px;
}
.sharedtopopup {
  width: 45%;
}

.sharedtoinput {
  width: 300px;
}

.sharedtopopup {
  width: 40%;
}

.sharedtoinputdatepicker {
  background-color: transparent;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  min-width: 283px;
  padding: 6px;
  text-indent: 6px;
}
.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 5px;
  height: 38px !important;
  margin: 1px 0;
  padding: 2px 5px;
  width: 300px;
}
.shareWithMeSearchBox {
  display: flex;
  justify-content: end;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 10px;
}
.addMedicineSearchBox {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 10px;
}
.ViewDeviceHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}
.SelectDeviceHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shareWithMeProfileBox {
  display: flex;
  /* justify-content: center; */
}
.pairBoxDashboard {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 45%;
  padding: 20px;
  border-radius: 10px;
}
.pairFlexSty {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}
.pairarrow {
  margin-top: 30px;
  width: 30px;
  height: 30px;
}
.arrowBox {
  width: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #4b89ac;
  height: 10px;
  margin-top: 9px;
}
.navbgcolor {
  background-color: #bfd8ef;
  width: 70px;
  border: 1px solid #4b89ac;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.shareBoxPart {
  margin-inline-start: 7%;
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .sharedtopopup {
    width: 70%;
  }
  .from-tilldate-sty {
    margin-left: 30px;
  }
  .from-till-data {
    margin-left: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .DeviceDataCardBox {
    /* margin-left: 25%; */
  }
  .from-tilldate-sty {
    margin-left: 10px;
  }
  .from-till-data {
    margin-left: 30px;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
  .withmebox {
    display: flex;
  }
  .access-data {
    margin-right: 0px !important;
  }
  .pairBoxDashboard {
    width: 100%;
  }
  .sharedtopopup {
    width: 89%;
  }
  .sharepopupinputbox {
    margin-left: 10px;
    margin-top: 10px;
  }
  .sharepopupinputbox > .sharedtoinput {
    width: 97%;
  }
  .sharepopupinputbox > h5 {
    width: max-content;
  }
  .rmdp-input {
    width: 220px;
  }
  .rmdp-ep-arrow {
    visibility: hidden !important;
  }
  .shareWithMeSearchBox {
    margin-bottom: 10px;
  }
  .addmedicinePopup2 {
    width: 77%;
  }
  .confirmPopup {
    width: 92%;
  }
  .popupPairBtn:hover {
    border-radius: 4px;
    width: 30%;
    border: 2px solid #13a62a;
    padding: 2px;
  }
  .sharedtoinputdatepicker {
    /* scale: 0.9; */
    min-width: 200px;
  }
}
@media (min-width: 739px) and (max-width: 1536px) {
  .from-tilldate-sty {
    margin-left: 0 !important;
  }
  .from-till-data {
    margin-left: -30 !important;
  }
  .nickname-input-sty {
    width: 130% !important;
  }
}
@media (min-width: 200px) and (max-width: 400px) {
  .sharedtoinputdatepicker {
    scale: 1;
    min-width: 100px;
  }
  #shareto-input {
    width: 100%;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
  .mob-manually-med {
    height: 400px;
    overflow: auto;
    width: 90% !important;
    /* left: 20px !important; */
    margin-left: 19px !important;
  }
  .mob-div-manually {
    scale: 0.8;
  }
}
@media (min-width: 376px) and (max-width: 600px) {
  .sharedtoinputdatepicker {
    scale: 1;
    min-width: 200px;
  }
  .mob-manually-med {
    height: 400px;
    overflow: auto;
    width: 90% !important;
    /* left: 20px !important; */
    margin-left: 19px !important;
  }
  .mob-div-manually {
    scale: 0.8;
    /* width: 30px; */
  }
  .confirmPopup {
    width: 92%;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0% !important;
    /* width: 100%; */
  }
  .navbgcolor {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }
  .popupPairBtn {
    width: 54% !important;
  }
  .withmebox {
    display: block;
  }
  .access-data {
    margin-right: 0px !important;
  }
  #tab_mobile {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .pairBoxDashboard {
    width: 100%;
    padding-inline: 16%;
  }
  .pairFlexSty {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 10px;
  }
  .createpair {
    margin-left: 0% !important;
  }
  .rmdp-ep-arrow {
    visibility: hidden !important;
  }
  .shareWithMeSearchBox {
    display: block;
  }
  .addMedicineSearchBox {
    display: block;
  }
  .shareWithMeSearchBox > .searchBtn {
    margin-block: 10px;
  }
  .addMedicineSearchBox > .searchBtn {
    margin-block: 10px;
  }
  .SelectDeviceHeading {
    display: block;
  }
  .shareWithMeProfileBox {
    justify-content: center;
  }
  .popupPairBtn:hover {
    border-radius: 4px;
    width: 30%;
    border: 2px solid #13a62a;
    padding: 2px;
  }
  .pairarrow {
    transform: rotate(90deg);
    margin-inline-start: 35%;
  }
  .pairBtnBox {
    margin-inline-start: 9%;
  }
  .sharedtopopup {
    margin-left: 20px;
  }
}
@media (max-width: 379px) {
  .sharedtoinputdatepicker {
    scale: 0.8;
    margin-left: -20px;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
  .confirmPopup {
    width: 92%;
  }
  .navbgcolor {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }
  .access-data {
    margin-right: 0px !important;
  }
  .pairBoxDashboard {
    width: 100%;
    padding-inline-start: 14%;
  }
  .pairFlexSty {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 10px;
  }

  .pairarrow {
    transform: rotate(90deg);
    margin-inline-start: 35%;
  }
  .pairBtnBox {
    margin-inline-start: 10%;
  }
  .sharepopupinputbox > .sharedtoinput {
    width: 97%;
  }
  .createpair {
    margin-left: 0% !important;
  }
  .rmdp-input {
    width: 97%;
  }
  .rmdp-ep-arrow {
    visibility: hidden !important;
  }
  .shareWithMeSearchBox {
    display: block;
  }
  .shareWithMeSearchBox > .searchBtn {
    margin-block: 10px;
  }
  .addMedicineSearchBox {
    display: block;
  }
  .addMedicineSearchBox > .searchBtn {
    margin-block: 10px;
  }
  .SelectDeviceHeading {
    display: block;
  }
  .shareWithMeProfileBox {
    justify-content: center;
  }
}
.createpair {
  background-color: transparent;
  border: none;
  margin-top: 5px;
  color: #e96f6f;
  margin-left: 33.8%;
  font-size: 17px;
}
.popupPairBtn {
  border-radius: 4px;
  width: 30%;
  padding: 2px;
  background-color: #13a62a;
  border: 2px solid #13a62a;
}
.popupPairBtn:hover {
  border-radius: 4px;
  width: 30%;
  border: 2px solid #13a62a;
  padding: 2px;
}
.access-data {
  /* border: 1px solid #a1a1a1; */
  padding: 4px;
  margin-left: 1px;
  margin-right: 45px;
  padding-top: 20px;
  border-radius: 6px;
  background: #ffffff;
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23); */
  width: 94%;
}
.text-between-border {
  margin-top: -36px;
  display: inline-block;
  /* padding: 0 2px; */
}
.title_bg {
  /* background-color: white; */
  /* font-family: "Open Sans",sans-serif; */
  text-transform: uppercase;
  font-weight: 600;
  color: #444;
  line-height: 0.8;
}
.editBtn {
  height: 28px;
  width: 28px;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.edit_btn_sty {
  background: none;
  border: none;
}
.sharedatedashboard {
  z-index: 999;
  position: relative;
}
.sharedatedashboard select {
  width: 90px;
  background-color: white;
  padding: 2px;
}
.sharetodataDatepicker select {
  width: 90px;
  background-color: white;
  padding: 2px;
  /* z-index: 1000; */
}
.DataShareToDpStyle {
  max-width: 200px !important;
  margin-top: 10px;
}
.idealpostion {
  transform: rotate(20deg);
  width: 30px;
  height: 30px;
}
.slightlypostion {
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
}
.incorrectpostion {
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
}
.next-btn-sty {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 95%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0% !important;
    /* width: 100%; */
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #sharewithmemobile {
    /* display: flex; */
    flex-direction: column !important;
    width: 100%;
    border-bottom: none;
  }
  .confirmPopup {
    width: 92%;
  }
  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sharepopupinputbox > .sharedtoinput {
    width: 87%;
  }
  .confirmPopup {
    width: 92%;
  }
  .from-tilldate-sty {
    margin-left: 30px;
  }
  .from-till-data {
    margin-left: 10px;
  }

  .no-data-sty {
    margin-top: 2%;
    margin-left: 0%;
    /* width: 100%; */
  }
  .shareBoxPart {
    margin-inline-start: -45%;
    width: 140%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sharedtoinputdatepicker {
    min-width: 270px;
  }
  .confirmPopup {
    width: 92%;
  }
  .access-data {
    margin-left: 1px;
    margin-right: 0px;
  }
  .withmebox {
    display: flex;
  }
}
.sideboxsharewith {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  overflow-x: scroll;
  scrollbar-width: none !important;
}
.sideboxsharewith::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
.sideboxsharewith::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sideboxsharewith::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
  height: 200px !important;
}
.refillBtn {
  background-color: transparent;
  border: 2px solid var(--ThemeTextColor);
  border-radius: 10px;
  color: #000;
  width: max-content;
  height: 40px;
  font-size: large;
  padding-inline: 10px;
}
.Device_select {
  width: 320px;
}
.confirmPopup {
  width: 50%;
}
.flex-container {
  display: flex;
  flex-direction: row;
  /* font-size: 30px; */
  /* text-align: center; */
}

.flex-item-left {
  background-color: #ecd5e3;
  padding: 10px;
  flex: 0% 1.5;
}

.flex-item-right {
  background-color: #ecd5e3;
  padding: 10px;
  flex: 30%;
}
.medicine-box {
  padding: 8px;
  width: 50%;
  margin-left: 26%;
}
.manually_medicine {
  width: 62%;
  justify-content: center;
  margin-left: 16%;
}
/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-item-left {
    background-color: #ecd5e3;
    padding: 7px;
    flex: 20% 1.5;
  }

  .flex-item-right {
    background-color: #ecd5e3;
    padding: 7px;
    flex: 30%;
  }
  .medicine-box {
    padding: 8px;
    width: 99%;
    margin-left: 0%;
  }
  .confirmPopup {
    width: 92%;
  }
}
.alreadyPaired {
  margin-top: -14px;
}
.pairRefillPopup {
  width: 35%;
}
.NumberOfDose {
  width: 220px;
  padding: 5px;
}
.no-data-sty {
  margin-top: 30%;
  margin-left: 200%;
  width: 100%;
}
.size-datepicker {
  min-width: 0 !important;
}
.text-pending {
  color: #e79c12;
  font-weight: 600;
}
.expiry-date-addmed {
  width: 130px;
}
.shareBTN {
  width: auto;
  padding: 5px 27px;
}
.shareBTN:hover {
  width: auto;
  padding: 5px 27px;
}
.main-width {
  width: 96%;
}
#text {
  display: block;
  color: #000;
  font-weight: 300;
  /* font-style: italic; */
  padding: 5px;
}
.refill-popup-datatable {
  height: 400px;
}
.datatable {
  height: 450px;
}
.table-heigth-refill {
  height: 459px !important;
}
.enddate-shareview {
  margin-left: 20px;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}
.mob-shareto-date {
  width: 90%;
}
.from-tilldate-sty {
  margin-left: 79px;
}
.nickname-input {
  width: 150%;
}
.nickname-input-sty {
  width: 150%;
}
.email-input-sty {
  width: 150%;
}
.select-device {
  width: 225%;
}

.access-data-error {
  margin-left: 38%;
}

.nickname-div {
  margin-left: 90px;
}
.email-phone-div {
  margin-left: 25px;
}
.fromdate-sty {
  margin-left: 38px;
}
.email-div-sty {
  margin-left: 23px;
}
.dataperiod-fromdate {
  margin-left: 72px;
}
.main-div-sty {
  display: flex;
  flex-wrap: wrap;
  gap: 5;
}
.email-div {
  display: flex;
  flex-wrap: wrap;
  gap: 4;
}
.select-device-sty {
  margin-left: 135px;
}
.alertify .ajs-dialog {
  /* margin-top: 20% !important; */
  background: #ffeded !important;
  width: 100%;
  margin-top: 20% !important;
  border-radius: 12px;
  color: #ff0000a8;
  box-shadow: inset 0 0 10px #9d120654;
  /* height: 123px; */
  text-align: center;
}
.alertify.ajs-movable:not(.ajs-maximized) .ajs-header {
  cursor: none !important;
  margin-left: 53px;
  background: transparent;
  width: 76%;
  margin-top: -9px;
  border-radius: 46px;
  /* color: #ff0000a8; */
  /* box-shadow: inset 0 0 10px #9d120654; */
}
.alertify .ajs-body .ajs-content {
  /* background: #FFEDED;
  width: 100%;
  margin-top: 4px;
  border-radius: 12px;
  color: #ff0000a8;
  box-shadow: inset 0 0 10px #9d120654; */
  height: 123px;
  text-align: center;
}
.medicine-expirydate {
  font-weight: 400;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: center !important;
  margin-top: 20px;
}
.alertify .ajs-footer {
  padding: 4px;
  margin-left: -24px;
  margin-right: -24px;
  min-height: 43px;
  /* background-color: #fff; */
  box-shadow: inset 0px -4px 4px #9d120654;
  background: #ffeded !important;
  /* width: 100%; */
  border-radius: 13px;
}
.alertButton {
  background-color: #fe7272;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: 100px;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px !important;
}
#main-alert-box {
  box-shadow: inset 5px -7px 35px #9d120654;
  background: #ffeded !important;
  color: #ed1919e6 !important;
  /* border-radius: 30px !important; */
}

.text-alert-title {
  color: #ed1919e6 !important;
}
.css-hlj6pa-MuiDialogActions-root {
  width: 61% !important;
}
.alertify .ajs-commands button.ajs-close {
  background-size: 17px;
}

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 25px;
    cursor: pointer;
    width: 64px;
    margin-top: 9px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 28%;
    z-index: 4;
    display: block;
    height: 104%;
    padding: 10px;
    left: 2px;
    width: 22px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-left: 1px;
    margin-top: -2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 146%;
    position: absolute;
    left: 50px;
    top: -6px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 94%;
    margin-left: -20px;
    margin-top: -2px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #19902bdb;
    box-shadow: inset 0 0 0 30px #45b556c2;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
}
.toggle-btn-div {
  border: 2px solid rgb(201 181 181 / 73%);
  border-radius: 20px;
  height: 32px;
  width: 81px;
}
.offbtn-sty-div {
  border: 1px solid rgb(88 83 83 / 73%);
  border-radius: 20px;
  height: 32px;
  width: 81px;
  background-color: #f24949;
}
.offbtn-sty-div-1 {
  border: 1px solid rgb(88 83 83 / 73%);
  border-radius: 20px;
  height: 32px;
  width: 81px;
  background-color: #55ad84;
}
.disable-btn {
  border: none;
  margin-left: 5px;
  /* border: none; */
  height: 23px;
  /* border: 2px solid green; */
  border-radius: 17px;
  border-right: none;
  background: #b9bab9;
  color: #b9bab9;
  width: 24px;
  /* margin-top: -81px; */
  /* overflow: hidden; */
  font-size: 1px;
  padding-bottom: -58px;
  padding-top: 14px;
}
.onbtn-sty {
  border: none;
  margin-left: 3px;
  /* border: none; */
  height: 23px;
  /* border: 2px solid green; */
  /* border-radius: 17px; */
  border-right: none;
  background: transparent;
  color: #fff;
  width: 27px;
  margin-top: 3px;
  overflow: hidden;
}
.enable-btn {
  /* border: none; */
  border: none;
  height: 33px;
  /* border: 2px solid green; */
  /* border-radius: 17px; */
  border-left: none;
  background: transparent;
  color: #fff;
  font-weight: 500;
  margin-left: 8px;
}
.offbtn-sty {
  border: none;
  height: 23px;
  /* border: 2px solid green; */
  /* border-radius: 17px; */
  border-left: none;
  background: #b9bab9;
  border-radius: 22px;
  width: 24px;
  overflow: hidden;
  color: #b9bab9;
  margin-left: 22px;
  /* margin-top: 2px; */
  font-size: 1px;
  padding-top: 15px;
}
.expiry-alert-checkbox {
  width: 46px;
  height: 16px;
}
.alert-expiry {
  padding: 0.5rem !important;
  margin-top: 5px !important;
}
.cancelbtn-pair {
  background-color: #fe7272;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: fit-content;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
}
.savebtn-pair:hover {
  background-color: white;
  border: 2px solid #13a62a;
  border-radius: 4px;
  color: #000;
  width: fit-content;
  font-size: large;
  height: 40px;
}
.savebtn-pair {
  background-color: #13a62a;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: fit-content;
  height: 40px;
  /* font-weight: 800; */
  font-size: large;
}

.pagination-select select {
  width: 45px !important;
  padding: 2% !important;
}