.weather-hero-box {
  /* background-image: url("../../assets/img/Weather_bg_1.png"); */
  height: 390px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #ffffff;
  border-radius: 20px;
}
.current-temp-text {
  font-size: 69px;
}
.current-location-text {
  font-size: 24px;
}
.weather-details-box {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.weather-data {
  background-color: #d5f7e6;
  height: 300px;
  width: 25%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 0.2px solid rgba(99, 99, 99, 0.096);
}
.weather-graph {
  background-color: white;
  height: 300px;
  width: 75%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 0.2px solid rgba(99, 99, 99, 0.096);
}
@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .weather-details-box {
    display: grid;
    justify-content: start;
    gap: 15px;
  }
  .weather-data {
    width: 80vw;
  }
  .weather-graph {
    width: 80vw;
  }
}
