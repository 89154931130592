.thumb-icon-sty{
    background-color: #3ab93a;
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.thumb-sty{
    height: 40px;
    width: 40px;
    color: #fff;
    margin-top: 10px;
}
.reset-pass-sty{
    height: 57px;
    width: 57px;
    color: #fff;
}
.text-sty{
    color: #667085;
}
.thumb-icon-sty-notverified{
    background-color:#fe7272;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin-left: 41%;
}
.thumb-sty-notverify{
    height: 47px;
    width: 77px;
    color: #fff;
    margin-top: 16px;
}
.thumb-icon-sty{
    background-color:  #3ab93a;
    border-radius: 50%;
    width: 70px;
    height: 70px;
}
.btn-width-verify{
    width: 42%;
}
.btn-width-verify:hover{
    width: 42%;
}
.verifyBTN{
    background-color: #1a2e35;
    width: 160px;
    height: 40px;
    color: white;
    border-radius: 8px;
}
.verifyBTN:hover{
    width: 160px;

}