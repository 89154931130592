.headerbg {
  background-color: #4b89ac;
  /* height: 10%; */
}

.logoImgSty {
  height: 40px;
  scale: 1;
  margin-top: 10px;
  margin-inline-start: 20%;
}

.nav-links {
  color: #ffffff !important;
  text-decoration: none !important;
  padding: 0.5rem 0.2rem;
  /* height: 100%; */
  border-bottom: 3px solid transparent;
  font-size: larger;
}

.nav-links:hover {
  color: #ffffff !important;
}

a.dropdown-menu-align-end.dropdown-toggle.nav-link {
  color: #000000 !important;
}

@media only screen and (max-device-width: 480px) {
  .logoImgSty {
    margin-left: 0%;
  }
}
@media only screen and (max-device-width: 980px) {
  .logoImgSty {
    margin-left: 0%;
  }
}

#headerText:hover {
  background-color: transparent;
}

#active1:hover {
  background-color: transparent;
}

#active1 {
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  font-size: 16px;
}

.navbar-toggler {
  color: #ffffff !important;
}

/* new */
header {
  border: black;
  border-width: 2px;
  box-shadow: 0px 3px 20px 3px rgba(0, 0, 0, 0.06);
}
.head_container {
  padding: 7px;
  overflow: hidden;
}
.logo {
  float: left;
  margin-left: 20px;
}
.logo img {
  height: 50px;
}
.menu {
  margin-top: 5px;
  float: right;
}
.menu ul {
  list-style-type: none;
  margin-right: -10px;
}
.menu li {
  display: inline;
  margin-right-last: 0px;
}
.menu a {
  text-decoration: none;
  font-weight: 500;
  color: #1c3664;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.menu.icon:hover {
  background: rgba(247, 202, 24, 0.1);
  border: none;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  transition: 0.2s;
}
.icon {
  display: none;
}

menu.responsive {
  position: relative;
}
menu.responsive {
  float: none;
  display: block;
  text-align: left;
}

@media screen and (max-width: 835px) {
  .logo img {
    height: 40px;
  }

  .menu {
    margin: 0;
  }
}

@media screen and (max-width: 690px) {
  .menu li {
    display: none;
  }
  .icon {
    font-weight: 900 !important;
    font-size: 27px !important;
    padding: 0;
    margin: 0px;
    margin-top: -22px;
    display: flex;
    color: #ffffff;
  }
  .logo img {
    height: 40px;
    margin-top: 16px;
  }
  .head_container {
    padding: 20px;
  }
}
@media screen and (max-width: 990px) {
  .menu li {
    display: none;
  }
  .icon {
    font-weight: 900 !important;
    font-size: 27px !important;
    padding: 0;
    /* margin: 14px; */
    margin-top: -10px;
    display: flex;
    color: #ffffff;
  }
  #mySidenav {
    height: 100%;
  }
  .head_container {
    padding: 13px;
    overflow: hidden;
  }
}
.openmenubar {
  color: #ffffff;
  scale: 1.7;
}
.sidenav.closemenubar {
  color: #ffffff;
}

.sidenav {
  /* height: 100%; */
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  /* left: 0; */
  right: 0;
  background-color: #4b89ac;
  overflow-x: hidden;
  transition: 0.2s;
  padding-top: 67px;
  box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.24);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 22px;
  color: #1c3664;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  left: -72px;
  font-size: 36px;
  margin-left: 50px;
  color: #ffffff;
}
.link_dropdown {
  text-decoration: none !important;
  color: #212529 !important;
}
.dropdown_item {
  color: #212529;
  text-align: center;
}
.languageinput {
  width: 130px;
  color: white;
  border: 1px solid white;
  border-bottom: 3px solid white;
}
.languageselect {
  color: #000000;
}
